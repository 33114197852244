import useSubscriptionDiscounts from "../../state/hooks/subscriptions-discounts/useSubscriptionDiscounts";
import {notification, Switch, Table} from "antd";
import useToggleSubscriptionDiscount from "../../state/hooks/subscriptions-discounts/toggleSubscriptionDiscount";
import {useEffect, useState} from "react";
import {IErrorResponse} from "../../state/querys/querys.enum";
import {AxiosError} from "axios"; // Using Ant Design for table

const SubscriptionDiscounts = () => {

   const {data,isLoading,error}=useSubscriptionDiscounts();

    const showSuccessNotification = (description:string) => {
        notification.success({
            message: 'Success',
            description,
            placement: 'top', // Optional: Controls the position of the popup
        });
    };


    const showErrorNotification = (error?:AxiosError<IErrorResponse>) => {
        notification.error({
            message: 'Error',
            description: !error ? 'Something went wrong. Please try again later.' :  typeof error === 'string' ? error : error?.response?.data?.message,
            placement: 'top', // Optional: Controls the position of the popup
        });
    };


    const mutation = useToggleSubscriptionDiscount({
        onSuccess: (data) => {
            showSuccessNotification(data.data.message);
        },
        onError: () => {
            showErrorNotification()
        },
    });

    useEffect(() => {
        if(error){
            showErrorNotification(error);
        }

    }, [error]);


    const toggleActiveStatus = (id: string) => {
        mutation.mutate(id);
    }


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Platform",
            dataIndex: "platform",
            key: "platform",
        },
        {
            title: "Product Month",
            dataIndex: "productMonth",
            key: "productMonth",
        },
        {
            title: "Discount Amount",
            dataIndex: "discountAmount",
            key: "discountAmount",
        },
        {
            title: "Discount Code",
            dataIndex: "discountCode",
            key: "discountCode",
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Is Active",
            dataIndex: "isActive",
            key: "isActive",
            render: (isActive:boolean, record:any) => (
                <Switch
                    checked={isActive}
                    onChange={() => toggleActiveStatus(record.id)}
                    // checkedChildren="Active"
                    // unCheckedChildren="Inactive"
                    style={{
                        backgroundColor: isActive ? '#2D3AC7' : '#D6D6D6', // Custom colors for checked and unchecked state
                        borderColor: isActive ? '#2D3AC7' : '#D6D6D6',
                    }}
                />
            ),
        },
    ];

    if (isLoading) {
        return <div className="text-center">Loading subscription discounts...</div>;
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Subscription Discounts
            </h1>
            <Table
                dataSource={data?.subscriptionDiscounts}
                columns={columns}
                rowKey="id"
                className="bg-white shadow-md rounded-lg"
                pagination={{
                    pageSize: 50, // Set the page limit to 50
                    showSizeChanger: true, // Optionally allow user to change the page size
                    pageSizeOptions: ["50", "100", "200"], // Optional options for the page size
                }}
                scroll={{
                    y: 800, // Vertical scroll with a fixed height (adjust as needed)
                }}
            />
        </div>
    );
}

export default SubscriptionDiscounts;