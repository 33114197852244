import {useAppQuery} from "../../querys/useAppQuery";
import {AxiosError} from "axios";
import {IErrorResponse, QueryKeys} from "../../querys/querys.enum";
import axiosInstance from "../../../utils/axios";
import {FilterDiscountsResponse} from "../../../models/Interface/subscription-discounts.interface";

const useSubscriptionDiscounts = () => {
    return useAppQuery<FilterDiscountsResponse, AxiosError<IErrorResponse>>({
        queryKey: [
            QueryKeys.SUBSCRIPTION_DISCOUNTS,
        ],
        queryFn: async () => {
            return (await axiosInstance.get(`/subscription-discounts/`)).data;
        },
        enabled: true,
        retry: 0,
    });
};
export default useSubscriptionDiscounts;