import axiosInstance, {CustomAxiosError} from "../../../utils/axios";
import {useMutation, UseMutationOptions, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import {MutationKeys} from "../../mutation/mutation.enum";
import {QueryKeys} from "../../querys/querys.enum";

interface ToggleDiscountResponse {
    success: boolean;
    message: string;
}

interface IUseToggleSubscriptionDiscount
    extends UseMutationOptions<AxiosResponse<ToggleDiscountResponse>, AxiosError, string> {}

const useToggleSubscriptionDiscount = (options?: IUseToggleSubscriptionDiscount) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: (planId: string) => {
            return axiosInstance.post(`/subscription-discounts/toggle-status/${planId}`);
        },
        mutationKey: [MutationKeys.TOGGLE_SUBSCRIPTION_DISCOUNT],
        onSuccess: (res, variables, context) => {
            // Update relevant queries or perform additional actions here
            // @ts-ignore
            queryClient.invalidateQueries(QueryKeys.SUBSCRIPTION_DISCOUNTS).then(r => r);
            options?.onSuccess?.(res, variables, context);
        },
        onError: (error: CustomAxiosError, variables, context) => {
            console.error('Error:', error?.response?.data?.message);
            options?.onError?.(error, variables, context);
        },
    });
};

export default useToggleSubscriptionDiscount;