// Navbar.tsx
import { useQueryClient } from "@tanstack/react-query";
import Logo from "../../assets/logo.png";
import NavbarLink from "./NavbarLinks/NavbarLink";
import useLogout from "../../state/hooks/auth/useLogout";
import Button from "../Button/Button";

function Navbar() {
  const { mutate: logout, isPending } = useLogout();

  const logOut = () => {
    logout();
  };

  return (
      <div className="h-screen min-w-[280px] max-w-[320px] bg-white flex flex-col py-8 shadow-lg">
        {/* Logo and Title Section */}
        <div className="flex items-center gap-6 px-8 mb-8">
          <img src={Logo} alt="logo" className="w-30" />
          <div className="text-xl font-extrabold">Talkpal</div>
        </div>

        {/* Navbar Links */}
        <nav className="flex-1 flex flex-col gap-2 px-8 overflow-y-auto">
          <NavbarLink linkTo="/main-users">Users</NavbarLink>
          <NavbarLink linkTo="/sub-users">Sub users</NavbarLink>
          <NavbarLink linkTo="/b2b-admins">B2B Admin Users</NavbarLink>
          <NavbarLink linkTo="/organizations">B2B Organizations</NavbarLink>
          <NavbarLink linkTo="/subscriptions">Subscriptions</NavbarLink>
          <NavbarLink linkTo="/subscription-discounts">Subscription Discounts</NavbarLink>
          <NavbarLink linkTo="/b2b-subscriptions">B2B Subscriptions</NavbarLink>
          <NavbarLink linkTo="/transactions">Transactions</NavbarLink>
          <NavbarLink linkTo="/translations">App - Translations</NavbarLink>
          <NavbarLink linkTo="/one-time-email">One time email</NavbarLink>
          <NavbarLink linkTo="/email-translations">Email - Translations</NavbarLink>
          <NavbarLink linkTo="/notification-translations">Notifications - Translations</NavbarLink>
          <NavbarLink linkTo="/landing-translations">Landing - Translations</NavbarLink>
          <NavbarLink linkTo="/prompts">Prompts</NavbarLink>
          <NavbarLink linkTo="/exports">Exports</NavbarLink>
        </nav>

        {/* Logout Button */}
        <div className="border-t border-light-grey-1 mt-8 pt-6 px-8">
          <Button
              onClick={logOut}
              disabled={isPending}
              className="text-lg w-full font-semibold py-3 text-red"
              type={isPending ? "secondary" : "main"}
          >
            Logout
          </Button>
        </div>
      </div>
  );
}

export default Navbar;