enum QueryKeys {
  USERS = 'USERS',
  USER = 'USER',
  APP_PARAMS = 'APP_PARAMS',
  LANGUAGES = 'LANGUAGES',
  SUB_USERS = 'SUB_USERS',
  SUB_USER = 'SUB_USER',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  SUBSCRIPTION = 'SUBSCRIPTION',
  PROMPTS = 'PROMPTS',
  PROMPT = 'PROMPT',
  ORGANIZATIONS = 'ORGANIZATIONS',
  ORGANIZATION = 'ORGANIZATION',
  B2B_ADMINS = 'B2B_ADMINS',
  B2B_ADMIN = 'B2B_ADMIN',
  B2B_SUBSCRIPTIONS = 'B2B_SUBSCRIPTIONS',
  B2B_SUBSCRIPTION = 'B2B_SUBSCRIPTION',
  USERS_EXPORTS = 'USERS_EXPORTS',
  SUBSCRIPTION_DISCOUNTS = 'SUBSCRIPTION_DISCOUNTS',
  TOGGLE_SUBSCRIPTION_DISCOUNT = 'TOGGLE_SUBSCRIPTION_DISCOUNT',
}

interface IErrorResponse {
  error: string | {
    response:{
        data: {
          message: string
        }
    }
  };
  message: string;
  statusCode: number;
}

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
};

export { QueryKeys, defaultQueryOptions };
export type { IErrorResponse };
