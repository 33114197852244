import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface NavbarLinkProps {
    children: ReactNode;
    linkTo: string;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({ children, linkTo }) => {
    const { pathname } = useLocation();

    return (
        <Link
            to={linkTo}
            className={`
        block
        py-3
        px-6
        mb-2
        rounded-md
        text-sm
        sm:text-base
        font-bold
        transition
        duration-200
        ease-in-out
        ${
                pathname === linkTo
                    ? "bg-light-grey-1 text-primary shadow-md"
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            }
      `}
        >
            {children}
        </Link>
    );
};

export default NavbarLink;
