import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/axios';
interface Translation {
  [section: string]: { [key: string]: string };
}
const TranslationManager: React.FC<{
  language: string;
  search: string;
  type?: 'app' | 'email' | 'notifications' | 'landing';
}> = ({ language, search, type = 'app' }) => {
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');
  const [translations, setTranslations] = useState<Translation>({});

  const { data } = useQuery({
    retryDelay: 0,
    retry: 0,
    queryKey: ['translations', type, language],
    queryFn: async () => {
      // custom url to fetch translations
      let langURL;
      switch (type) {
        case 'email':
          langURL = `https://email-language.talkpal.ai/${language}.json`;
          break;
        case 'notifications':
          langURL = `https://notification-language.talkpal.ai/${language}.json`;
          break;
        case 'landing':
          langURL = `https://landing-language.talkpal.ai/${language}.json`;
          break;
        case 'app':
        default:
          langURL = `https://language.talkpal.ai/${language}.json`;
          break;
      }
      const jsonData = await fetch(langURL, {
        cache: 'no-cache',
      }).then((res) => res.json());

      // Handling different json structure
      if (Object.keys(jsonData)?.[0] !== 'Common') {
        return { Common: jsonData };
      }

      return jsonData as Translation;
    },
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      locale = 'en',
      translation,
    }: {
      locale: string;
      translation: Translation;
    }) => {
      let langURL;
      switch (type) {
        case 'email':
          langURL = `${process.env.REACT_APP_BASE_URL}/s3/update-interface-language/${locale}/email`;
          break;
        case 'notifications':
          langURL = `${process.env.REACT_APP_BASE_URL}/s3/update-interface-language/${locale}/notification`;
          break;
        case 'landing':
          langURL = `${process.env.REACT_APP_BASE_URL}/s3/update-interface-language/${locale}/landing`;
          break;
        case 'app':
        default:
          langURL = `${process.env.REACT_APP_BASE_URL}/s3/update-interface-language/${locale}/ui`;
          break;
      }
      const resp = await axiosInstance.put(langURL, {
        jsonBody: JSON.stringify(translation),
      });
      return resp.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['translations'],
      });
    },
  });

  const { mutate: translateKey, isPending: isTranslating } = useMutation({
    mutationFn: async ({ key, type }: { key: string; type: string }) => {
      let requestType: string;
      switch (type) {
        case 'email':
          requestType = 'email';
          break;
        case 'notifications':
          requestType = 'notification';
          break;
        case 'landing':
          requestType = 'landing';
          break;
        case 'app':
        default:
          requestType = 'ui';
          break;
      }

      const resp = await axiosInstance.post('/s3/translate-interface-keys', {
        key,
        type: requestType,
      });

      alert(JSON.stringify(resp.data) || 'Key translation successful!');
      queryClient.invalidateQueries({ queryKey: ['translations'] });
      return resp.data;
    },
    onError: (error: any) => {
      alert(error?.response?.data?.message || 'Failed to translate key.');
    },
  });

  const onAddKey = (section: string) => {
    if (newKey && !translations[section][newKey]) {
      const data = {
        ...translations,
        [section]: {
          ...translations[section],
          [newKey]: newValue,
        },
      };
      setNewKey('');
      setNewValue('');
      setTranslations(data);
    } else {
      alert('Key already exists');
    }
  };

  useEffect(() => {
    if (data) {
      setTranslations(data);
    }
  }, [data]);

  const diff = JSON.stringify(translations) !== JSON.stringify(data);
  const onSave = () => {
    if (!diff) {
      alert('No changes to save');
      return;
    }
    mutate({
      locale: language,
      translation: translations,
    });
  };
  return (
    <div className="flex flex-1 px-4  ">
      {isPending && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded">Loading...</div>
        </div>
      )}
      {isTranslating && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded">Translating...</div>
        </div>
      )}
      {Object.keys(translations).map((section) => (
        <div key={section} className="mb-6  w-full ">
          <h2 className="text-xl fixed font-bold mb-4 bg-opacity-60 bg-white ">
            {' Active Language: ' + language + ' ' + type}
          </h2>
          <h3 className="text-xl font-bold mb-4 mt-12">Section: {section}</h3>
          <div>
            <table className="min-w-full bg-transparent rounded mb-4">
              <thead className="bg-gray-100">
                <tr className="flex">
                  <th
                    className="py-2 px-4 text-left"
                    style={{ flex: '0 0 150px' }}
                  >
                    Key
                  </th>
                  <th className="py-2 px-4 text-left" style={{ flex: '1' }}>
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(translations[section]).map(([key, value]) => {
                  if (
                    search &&
                    !key.includes(search) &&
                    !value.includes(search)
                  )
                    return;

                  return (
                    <tr key={key} className="flex">
                      {language === 'en' && (
                        <td className="border-t flex items-center pr-2">
                          <button
                            className="bg-green-500 h-full text-black rounded border border-primary"
                            onClick={() => translateKey({ key, type })}
                          >
                            Translate Key
                          </button>
                        </td>
                      )}
                      <td
                        className="border-t flex items-center"
                        style={{ flex: '0 0 150px' }}
                      >
                        {key}
                      </td>
                      <td
                        className="border-t flex items-center"
                        style={{ flex: '1' }}
                      >
                        <input
                          className="w-full border p-1 rounded"
                          value={value}
                          onChange={(e) => {
                            setTranslations({
                              ...translations,
                              [section]: {
                                ...translations[section],
                                [key]: e.target.value,
                              },
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-4 flex space-x-2 flex-1 justify-center items-center">
              {language === 'en' && (
                <>
                  <input
                    className="border px-4 py-2 rounded w-1/3"
                    placeholder="New Key"
                    value={newKey}
                    onChange={(e) => {
                      setNewKey(e.target.value);
                    }}
                  />
                  <input
                    className="border px-4 py-2 rounded w-1/3"
                    placeholder="New Value"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                  />
                  <button
                    className="bg-green-500 text-black px-4 py-2 rounded"
                    onClick={() => {
                      onAddKey(section);
                    }}
                  >
                    Add Key/Value
                  </button>
                </>
              )}

              <button
                className={`bg-green-500 text-black px-4 py-2 rounded border border-primary ${
                  diff ? 'bg-primary !text-white' : 'bg-gray-300'
                }`}
                onClick={() => {
                  diff && onSave();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TranslationManager;
